/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import { Global } from '@emotion/react'
import SEO from './seo'
import Head from '@/components/Home/Head'
import { FooterList } from './FooterList'
import Footer from './footer'
import '../styles/global.css'

type LayoutProps = { children: React.ReactNode; className?: string }

const Layout = ({ children, className = `` }: LayoutProps) => (
  <React.Fragment>
    <Global
      styles={(theme) => ({
        '*': {
          boxSizing: `inherit`,
          '&:before': {
            boxSizing: `inherit`,
          },
          '&:after': {
            boxSizing: `inherit`,
          },
        },
        html: {
          fontSize: `18px`,
          WebkitTextSizeAdjust: `100%`,
        },
        img: {
          borderStyle: `none`,
        },
        pre: {
          fontFamily: `monospace`,
          fontSize: `1em`,
        },
        '[hidden]': {
          display: `none`,
        },
        // '::selection': {
        //   backgroundColor: theme.colors.primary,
        //   color: theme.colors.background,
        // },
      })}
    />
    <SEO />
    <Head />
    <main
      className={className}
      style={{
        margin: '0 auto',
        maxWidth: '1200px',
        padding: '0 1rem',
      }}
    >
      {children}
    </main>
    <FooterList />
    <Footer />
  </React.Fragment>
)

export default Layout

import { Box, jsx } from 'theme-ui'
import React from 'react'
import { Link } from 'gatsby'

export const FooterList = () => {
  const sections = [
    {
      title: '注目のスキルキーワード',
      items: [
        'PHP',
        'Ruby',
        'Java',
        'Python',
        'TypeScript',
        'Azure',
        'AWS',
        'Linux',
        'Swift',
        'Kotlin',
      ],
    },
    {
      title: '注目の職種キーワード',
      items: [
        'フロントエンドエンジニア',
        'QA/テストエンジニア',
        'インフラエンジニア',
        'ネットワークエンジニア',
        'プロジェクトマネージャー',
        'フルスタックエンジニア',
      ],
    },
    {
      title: '職種',
      items: [
        '開発系',
        'フルスタックエンジニア',
        'フロントエンドエンジニア',
        'オープン系SE・プログラマ',
        '汎用系エンジニア',
        'ゲーム系エンジニア',
        '制御・組込エンジニア',
        'QA/テストエンジニア',
        'スマホアプリエンジニア',
        'コーダー/マークアップエンジニア',
        'サーバーサイドエンジニア',
        'インフラ系',
        'インフラエンジニア',
        'ネットワークエンジニア',
        'セキュリティエンジニア',
        'クラウドエンジニア',
        'データベースエンジニア',
        'ITコンサルタント系',
        'ITコンサルタント',
        'プリセールス',
        'データサイエンティスト',
        '管理系',
        'プロジェクトマネージャー',
        'プロダクトマネージャー',
        'PMO',
        'CTO/VPoE',
        'ブリッジSE',
        'その他',
        'IT講師・トレーナー',
        'クリエイター系',
        'webデザイナー',
        'webディレクター',
        'UI/UXデザイナー',
        'バックオフィス系',
        '社内SE',
        'ヘルプデスク',
        'カスタマーサクセス/サポート',
      ],
    },
    {
      title: '業種',
      items: [
        'サービス',
        'SIer',
        '通信',
        'インターネット',
        'ソフトウェア',
        'メディア',
        'ゲーム',
        '広告',
        'web制作',
        'ハードウェア',
        '金融・不動産',
        'メーカー',
        '小売り',
        '流通',
        '医療',
        'コンサルティング',
      ],
    },
    {
      title: '就業形態',
      items: ['フリーランス（常駐）', 'フリーランス（在宅）'],
    },
    {
      title: '勤務地',
      items: [
        '北海道',
        '東北',
        '茨城県',
        '栃木県',
        '群馬県',
        '埼玉県',
        '千葉県',
        '東京都',
        '神奈川県',
        '23区外',
        '江戸川区',
        '品川区',
        '目黒区',
        '大田区',
        '世田谷区',
        '渋谷区',
        '中野区',
        '豊島区',
        '板橋区',
        '杉並区',
        '中部',
        '近畿',
        '九州',
        '中国・四国',
        '沖縄',
        'フルリモート',
      ],
    },
    {
      title: 'スキル',
      items: [
        '言語',
        'HTML・CSS',
        'JavaScript',
        'TypeScript',
        'PHP',
        'Ruby',
        'Perl',
        'Python',
        'Go',
        'Objective-C',
        'Swift',
        'Java',
        'Scala',
        'C',
        'C++',
        'C#',
        'VBA',
        'VB.Net',
        'VB Script',
        'VBA',
        'COBOL',
        'ABAP',
        'Delphi',
        'SQL',
        'PL/SQL',
        'VC++',
        'Dart(Flutter)',
        '.net',
        'Kotlin',
        'フレームワーク',
        'Symphony',
        'Zend Framework',
        'CakePHP',
        'FuelPHP',
        'CodeIgniter',
        'Play Framework',
        'Spring',
        'Seasar2',
        'Ruby on Rails',
        '.Net Framework',
        'Laravel',
        'Angular',
        'Vue.js',
        'Sinatra',
        'Padrino',
        'Catalyst',
        'Dancer',
        'Django',
        'Flask',
        'Bottle',
        'Gin',
        'Echo',
        'Perfect',
        'Kitura',
        'Spring Boot',
        'VB.NET',
        'Ktor',
        'Flutter',
        'Swift UI',
        'Struts',
        'Next.js',
        'ライブラリ',
        'jQuery',
        'Node.js',
        'Ajax',
        'Vue.js',
        'React',
        'OS',
        'Windows',
        'Linux',
        'UNIX',
        'Solaris',
        'AIX',
        'HP-UX',
        'Android',
        'iOS',
        'インフラ',
        'Apache',
        'IIS',
        'BIND',
        'PostFix',
        'VMware',
        'GCP',
        'Azure',
        'Docker',
        'ネットワーク',
        'Cisco',
        'Yamaha Router Switch',
        'ツール・ミドルウェア',
        'Unity',
        '3ds max',
        'after effects',
        'Cocos2d-x',
        'Eclipse',
        'GitHub',
        'SVN',
        'Hadoop',
        'Cassandra',
        'Mybatis',
        'TomCat',
        'ActiveDirectory',
        'BackUP Exec',
        'Arcserve',
        'Systemwalker',
        'JP1',
        'Tivoli',
        'OpenView',
        'Jenkins',
        'Selenium',
        'JUnit',
        'Git',
        'Maya',
        'Photoshop/illustrator',
        'Salesforce',
        'SAP',
        'SharePoint',
      ],
    },
  ]

  return (
    <Box
      sx={{
        backgroundColor: '#333',
        color: 'white',
        padding: '40px 40px',
        fontSize: ['12px', '14px'],
      }}
    >
      {sections.map((section, index) => (
        <Box key={index} sx={{ marginBottom: '24px' }}>
          <h3
            style={{
              fontSize: '20px',
              color: '#fff',
              marginBottom: '20px',
            }}
          >
            {section.title}
          </h3>
          <ul
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              maxWidth: '100%',
              gap: '12px 20px',
              padding: 0,
              margin: 0,
            }}
          >
            {section.items.map((item, i) => (
              <li
                key={i}
                style={{
                  display: 'block',
                  color: '#fff',
                  whiteSpace: 'nowrap',
                }}
              >
                {item}
              </li>
            ))}
          </ul>
        </Box>
      ))}
    </Box>
  )
}
